import Link from 'next/link';
import Avatar from './avatar';
import DateFormatter from './date-formatter';
import CoverImage from './cover-image';

export default function HeroPost({ title, coverImage, date, excerpt, author, slug }) {
  return (
    <section>
      <div className="mb-8 md:mb-16">
        <CoverImage title={title} src={coverImage} slug={slug} height={620} width={1240} />
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-x-16 lg:gap-x-8 mb-20 md:mb-28">
        <div>
          <h3 className="mb-4 text-4xl lg:text-6xl leading-tight">
            <Link as={`/${slug}`} href="/[slug]" passHref={true} className="hover:underline">
              {title}
            </Link>
          </h3>
          <div className="mb-4 md:mb-0 text-lg">
            <DateFormatter dateString={date} />
          </div>
        </div>
        <div>
          <Link
            as={`/${slug}`}
            href="/[slug]"
            passHref={true}
            className="text-lg leading-relaxed mb-4 cursor-pointer"
          >
            {excerpt}
          </Link>
          <a href={author.link || ''} target="_blank" rel="noreferrer">
            <Avatar name={author.name} picture={author.picture} />
          </a>
        </div>
      </div>
    </section>
  );
}
